export const MAPOSAIC_PALETTES = {
  '247ba070c1b3b2dbbff3ffbdff1654': {
    colors: ['#247ba0', '#70c1b3', '#b2dbbf', '#f3ffbd', '#ff1654'],
    specificTransforms: {},
  },
  '05668d02809000a89602c39af0f3bd': {
    colors: ['#05668d', '#028090', '#00a896', '#02c39a', '#f0f3bd'],
    specificTransforms: {},
  },
  '2646532a9d8fe9c46af4a261e76f51': {
    colors: ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'],
    specificTransforms: {},
  },
  '50514ff25f5cffe066247ba070c1b3': {
    colors: ['#50514f', '#f25f5c', '#ffe066', '#247ba0', '#70c1b3'],
    specificTransforms: {},
  },
  ffffff00171f003459007ea700a8e8: {
    colors: ['#ffffff', '#00171f', '#003459', '#007ea7', '#00a8e8'],
    specificTransforms: {},
  },
  ef476fffd16606d6a0118ab2073b4c: {
    colors: ['#ef476f', '#ffd166', '#06d6a0', '#118ab2', '#073b4c'],
    specificTransforms: {},
  },
  bce7845dd39e348aa7525174513b56: {
    colors: ['#bce784', '#5dd39e', '#348aa7', '#525174', '#513b56'],
    specificTransforms: {},
  },
  '22223b4a4e699a8c98c9ada7f2e9e4': {
    colors: ['#22223b', '#4a4e69', '#9a8c98', '#c9ada7', '#f2e9e4'],
    specificTransforms: {},
  },
  '114b5f1a936f88d498c6dabff3e9d2': {
    colors: ['#114b5f', '#1a936f', '#88d498', '#c6dabf', '#f3e9d2'],
    specificTransforms: {},
  },
  '7bdff2b2f7efeff7f6f7d6e0f2b5d4': {
    colors: ['#7bdff2', '#b2f7ef', '#eff7f6', '#f7d6e0', '#f2b5d4'],
    specificTransforms: {},
  },
  ffb997f67e7d843b620b032d74546a: {
    colors: ['#ffb997', '#f67e7d', '#843b62', '#0b032d', '#74546a'],
    specificTransforms: {},
  },
  c9cba3ffe1a8e26d5c723d46472d30: {
    colors: ['#c9cba3', '#ffe1a8', '#e26d5c', '#723d46', '#472d30'],
    specificTransforms: {},
  },
  ffa69efaf3ddb8f2e6aed9e05e6472: {
    colors: ['#ffa69e', '#faf3dd', '#b8f2e6', '#aed9e0', '#5e6472'],
    specificTransforms: {},
  },
  '540d6eee4266ffd23f3bceac0ead69': {
    colors: ['#540d6e', '#ee4266', '#ffd23f', '#3bceac', '#0ead69'],
    specificTransforms: {},
  },
  '1b998b2d3047fffd82ff9b71e84855': {
    colors: ['#1b998b', '#2d3047', '#fffd82', '#ff9b71', '#e84855'],
    specificTransforms: {},
  },
  b8d8bad9dbbcfcddbcef959d69585f: {
    colors: ['#b8d8ba', '#d9dbbc', '#fcddbc', '#ef959d', '#69585f'],
    specificTransforms: {},
  },
  f8ffe506d6a01b9aaaef476fffc43d: {
    colors: ['#f8ffe5', '#06d6a0', '#1b9aaa', '#ef476f', '#ffc43d'],
    specificTransforms: {},
  },
  ffac81ff928bfec3a6efe9aecdeac0: {
    colors: ['#ffac81', '#ff928b', '#fec3a6', '#efe9ae', '#cdeac0'],
    specificTransforms: {},
  },
  '0b132b1c25413a506b5bc0beffffff': {
    colors: ['#0b132b', '#1c2541', '#3a506b', '#5bc0be', '#ffffff'],
    specificTransforms: {},
  },
  f7b267f79d65f4845ff27059f25c54: {
    colors: ['#f7b267', '#f79d65', '#f4845f', '#f27059', '#f25c54'],
    specificTransforms: {},
  },
  '2319425e548e9f86c0be95c4e0b1cb': {
    colors: ['#231942', '#5e548e', '#9f86c0', '#be95c4', '#e0b1cb'],
    specificTransforms: {},
  },
  '26547cef476fffd16606d6a0fffcf9': {
    colors: ['#26547c', '#ef476f', '#ffd166', '#06d6a0', '#fffcf9'],
    specificTransforms: {},
  },
  '03256c2541b21768ac06bee1ffffff': {
    colors: ['#03256c', '#2541b2', '#1768ac', '#06bee1', '#ffffff'],
    specificTransforms: {},
  },
  '64a6bd90a8c3ada7c9d7b9d5f4cae0': {
    colors: ['#64a6bd', '#90a8c3', '#ada7c9', '#d7b9d5', '#f4cae0'],
    specificTransforms: {},
  },
  '26547cef476fffd16606d6a0fcfcfc': {
    colors: ['#26547c', '#ef476f', '#ffd166', '#06d6a0', '#fcfcfc'],
    specificTransforms: {},
  },
  ffe74cff59646bf17835a7ff: {
    colors: ['#ffe74c', '#ff5964', '#6bf178', '#35a7ff'],
    specificTransforms: {},
  },
  '0000002f4550586f7cb8dbd9f4f4f9': {
    colors: ['#000000', '#2f4550', '#586f7c', '#b8dbd9', '#f4f4f9'],
    specificTransforms: {},
  },
  fe4a492ab7cafed766e6e6eaf4f4f8: {
    colors: ['#fe4a49', '#2ab7ca', '#fed766', '#e6e6ea', '#f4f4f8'],
    specificTransforms: {},
  },
  a9e5bbfcf6b1f7b32bf72c252d1e2f: {
    colors: ['#a9e5bb', '#fcf6b1', '#f7b32b', '#f72c25', '#2d1e2f'],
    specificTransforms: {},
  },
  '565264706677a6808cccb7aed6cfcb': {
    colors: ['#565264', '#706677', '#a6808c', '#ccb7ae', '#d6cfcb'],
    specificTransforms: {},
  },
  '3c16420863751dd3b0affc41b2ff9e': {
    colors: ['#3c1642', '#086375', '#1dd3b0', '#affc41', '#b2ff9e'],
    specificTransforms: {},
  },
  '52489c4062bb59c3c3ebebebf45b69': {
    colors: ['#52489c', '#4062bb', '#59c3c3', '#ebebeb', '#f45b69'],
    specificTransforms: {},
  },
  '5d737e64b6acc0fdfbdaffeffcfffd': {
    colors: ['#5d737e', '#64b6ac', '#c0fdfb', '#daffef', '#fcfffd'],
    specificTransforms: {},
  },
  '36213e55497163768d8ac6d0b8f3ff': {
    colors: ['#36213e', '#554971', '#63768d', '#8ac6d0', '#b8f3ff'],
    specificTransforms: {},
  },
  '0d13211d2d443e5c76748cabf0ebd8': {
    colors: ['#0d1321', '#1d2d44', '#3e5c76', '#748cab', '#f0ebd8'],
    specificTransforms: {},
  },
  c9e4ca87bba255828b3b6064364958: {
    colors: ['#c9e4ca', '#87bba2', '#55828b', '#3b6064', '#364958'],
    specificTransforms: {},
  },
  fe5f55f0b67fd6d1b1c7efcfeef5db: {
    colors: ['#fe5f55', '#f0b67f', '#d6d1b1', '#c7efcf', '#eef5db'],
    specificTransforms: {},
  },
  bfc3baa9aca960495a3f32442f2235: {
    colors: ['#bfc3ba', '#a9aca9', '#60495a', '#3f3244', '#2f2235'],
    specificTransforms: {},
  },
  ff595effca3a8ac9261982c46a4c93: {
    colors: ['#ff595e', '#ffca3a', '#8ac926', '#1982c4', '#6a4c93'],
    specificTransforms: {},
  },
  '1a1423372549774c60b75d69eacdc2': {
    colors: ['#1a1423', '#372549', '#774c60', '#b75d69', '#eacdc2'],
    specificTransforms: {},
  },
  b0d0d3c08497f7af9df7e3aff3eec3: {
    colors: ['#b0d0d3', '#c08497', '#f7af9d', '#f7e3af', '#f3eec3'],
    specificTransforms: {},
  },
  '773344e3b5a4f5e9e20b0014d44d5c': {
    colors: ['#773344', '#e3b5a4', '#f5e9e2', '#0b0014', '#d44d5c'],
    specificTransforms: {},
  },
  fffcf2ccc5b9403d39252422eb5e28: {
    colors: ['#fffcf2', '#ccc5b9', '#403d39', '#252422', '#eb5e28'],
    specificTransforms: {},
  },
  '0fa3b1d9e5d6eddea4f7a072ff9b42': {
    colors: ['#0fa3b1', '#d9e5d6', '#eddea4', '#f7a072', '#ff9b42'],
    specificTransforms: {},
  },
  f9dbbdffa5abda627da53860450920: {
    colors: ['#f9dbbd', '#ffa5ab', '#da627d', '#a53860', '#450920'],
    specificTransforms: {},
  },
  '0026260e474995c623e55812efe7da': {
    colors: ['#002626', '#0e4749', '#95c623', '#e55812', '#efe7da'],
    specificTransforms: {},
  },
  ef6461e4b363e8e9ebe0dfd5313638: {
    colors: ['#ef6461', '#e4b363', '#e8e9eb', '#e0dfd5', '#313638'],
    specificTransforms: {},
  },
  ff4e008ea604f5bb00ec9f05bf3100: {
    colors: ['#ff4e00', '#8ea604', '#f5bb00', '#ec9f05', '#bf3100'],
    specificTransforms: {},
  },
  d7263df460362e294e1b998bc5d86d: {
    colors: ['#d7263d', '#f46036', '#2e294e', '#1b998b', '#c5d86d'],
    specificTransforms: {},
  },
  '0d1b2a1b263b415a77778da9e0e1dd': {
    colors: ['#0d1b2a', '#1b263b', '#415a77', '#778da9', '#e0e1dd'],
    specificTransforms: {},
  },
  cc5803e2711dff9505ffb627ffc971: {
    colors: ['#cc5803', '#e2711d', '#ff9505', '#ffb627', '#ffc971'],
    specificTransforms: {},
  },
  '084c61db504ae3b5054f6d7a56a3a6': {
    colors: ['#084c61', '#db504a', '#e3b505', '#4f6d7a', '#56a3a6'],
    specificTransforms: {},
  },
  d5c5c89da3a4604d53db7f8effdbda: {
    colors: ['#d5c5c8', '#9da3a4', '#604d53', '#db7f8e', '#ffdbda'],
    specificTransforms: {},
  },
  '006ba60496ffffbc42d811598f2d56': {
    colors: ['#006ba6', '#0496ff', '#ffbc42', '#d81159', '#8f2d56'],
    specificTransforms: {},
  },
  cae7b9f3de8aeb94867e7f9a97a7b3: {
    colors: ['#cae7b9', '#f3de8a', '#eb9486', '#7e7f9a', '#97a7b3'],
    specificTransforms: {},
  },
  e09f7def5d60ec4067a01a7d311847: {
    colors: ['#e09f7d', '#ef5d60', '#ec4067', '#a01a7d', '#311847'],
    specificTransforms: {},
  },
  ff6700ebebebc0c0c03a6ea5004e98: {
    colors: ['#ff6700', '#ebebeb', '#c0c0c0', '#3a6ea5', '#004e98'],
    specificTransforms: {},
  },
  e1ce7afbffb9fdd692ec7357754f44: {
    colors: ['#e1ce7a', '#fbffb9', '#fdd692', '#ec7357', '#754f44'],
    specificTransforms: {},
  },
  f7c59f2a324b767b91c7ccdbe1e5ee: {
    colors: ['#f7c59f', '#2a324b', '#767b91', '#c7ccdb', '#e1e5ee'],
    specificTransforms: {},
  },
  '484a475c6d70a37774e88873e0ac9d': {
    colors: ['#484a47', '#5c6d70', '#a37774', '#e88873', '#e0ac9d'],
    specificTransforms: {},
  },
  '0c090de01a4ff15946f9c22e53b3cb': {
    colors: ['#0c090d', '#e01a4f', '#f15946', '#f9c22e', '#53b3cb'],
    specificTransforms: {},
  },
  '463f3a8a817cbcb8b1f4f3eee0afa0': {
    colors: ['#463f3a', '#8a817c', '#bcb8b1', '#f4f3ee', '#e0afa0'],
    specificTransforms: {},
  },
  '11151c212d403641567d4e57d66853': {
    colors: ['#11151c', '#212d40', '#364156', '#7d4e57', '#d66853'],
    specificTransforms: {},
  },
  '233d4dfe7f2dfcca46a1c181619b8a': {
    colors: ['#233d4d', '#fe7f2d', '#fcca46', '#a1c181', '#619b8a'],
    specificTransforms: {},
  },
  '177e89084c61db3a34ffc857323031': {
    colors: ['#177e89', '#084c61', '#db3a34', '#ffc857', '#323031'],
    specificTransforms: {},
  },
  fdc5f5f7aef8b388eb8093f172ddf7: {
    colors: ['#fdc5f5', '#f7aef8', '#b388eb', '#8093f1', '#72ddf7'],
    specificTransforms: {},
  },
  ffc09fffee93fcf5c7a0ced9adf7b6: {
    colors: ['#ffc09f', '#ffee93', '#fcf5c7', '#a0ced9', '#adf7b6'],
    specificTransforms: {},
  },
  '3a2e391e555cf4d8cdedb183f15152': {
    colors: ['#3a2e39', '#1e555c', '#f4d8cd', '#edb183', '#f15152'],
    specificTransforms: {},
  },
  '588b8bffffffffd5c2f28f3bc8553d': {
    colors: ['#588b8b', '#ffffff', '#ffd5c2', '#f28f3b', '#c8553d'],
    specificTransforms: {},
  },
  '0a1128001f540340781282a2fefcfb': {
    colors: ['#0a1128', '#001f54', '#034078', '#1282a2', '#fefcfb'],
    specificTransforms: {},
  },
  ddd8c4a3c9a884b59f69a29750808e: {
    colors: ['#ddd8c4', '#a3c9a8', '#84b59f', '#69a297', '#50808e'],
    specificTransforms: {},
  },
  db504aff6f5925444143aa8bb2b09b: {
    colors: ['#db504a', '#ff6f59', '#254441', '#43aa8b', '#b2b09b'],
    specificTransforms: {},
  },
  '320a285117308e443dcb9173e0d68a': {
    colors: ['#320a28', '#511730', '#8e443d', '#cb9173', '#e0d68a'],
    specificTransforms: {},
  },
  '0d3b66faf0caf4d35eee964bf95738': {
    colors: ['#0d3b66', '#faf0ca', '#f4d35e', '#ee964b', '#f95738'],
    specificTransforms: {},
  },
  dd6e42e8dab24f6d7ac0d6dfeaeaea: {
    colors: ['#dd6e42', '#e8dab2', '#4f6d7a', '#c0d6df', '#eaeaea'],
    specificTransforms: {},
  },
  '102542f87060cdd7d6b3a394ffffff': {
    colors: ['#102542', '#f87060', '#cdd7d6', '#b3a394', '#ffffff'],
    specificTransforms: {},
  },
  ff99c8fcf6bdd0f4dea9def9e4c1f9: {
    colors: ['#ff99c8', '#fcf6bd', '#d0f4de', '#a9def9', '#e4c1f9'],
    specificTransforms: {},
  },
  ffffff81f4e156cbf9ff729fd3c4d1: {
    colors: ['#ffffff', '#81f4e1', '#56cbf9', '#ff729f', '#d3c4d1'],
    specificTransforms: {},
  },
  eca400eaf8bf00699227476e001d4a: {
    colors: ['#eca400', '#eaf8bf', '#006992', '#27476e', '#001d4a'],
    specificTransforms: {},
  },
  '2111033d13087b0d1e9f2042f8e5ee': {
    colors: ['#211103', '#3d1308', '#7b0d1e', '#9f2042', '#f8e5ee'],
    specificTransforms: {},
  },
  '040f0f2482322ba84a2d3a3afcfffc': {
    colors: ['#040f0f', '#248232', '#2ba84a', '#2d3a3a', '#fcfffc'],
    specificTransforms: {},
  },
  '5f0f409a031efb8b24e364140f4c5c': {
    colors: ['#5f0f40', '#9a031e', '#fb8b24', '#e36414', '#0f4c5c'],
    specificTransforms: {},
  },
  f9c80ef86624ea3546662e9b43bccd: {
    colors: ['#f9c80e', '#f86624', '#ea3546', '#662e9b', '#43bccd'],
    specificTransforms: {},
  },
  '0c0a3e7b1e7ab33f62f9564ff3c677': {
    colors: ['#0c0a3e', '#7b1e7a', '#b33f62', '#f9564f', '#f3c677'],
    specificTransforms: {},
  },
  '3900999e0059ff0054ff5400ffbd00': {
    colors: ['#390099', '#9e0059', '#ff0054', '#ff5400', '#ffbd00'],
    specificTransforms: {},
  },
  f79256fbd1a27dcfb600b2ca1d4e89: {
    colors: ['#f79256', '#fbd1a2', '#7dcfb6', '#00b2ca', '#1d4e89'],
    specificTransforms: {},
  },
  '202c39283845b8b08df2d492f29559': {
    colors: ['#202c39', '#283845', '#b8b08d', '#f2d492', '#f29559'],
    specificTransforms: {},
  },
  '0505051b9aaadddbcbf5f1e3ffffff': {
    colors: ['#050505', '#1b9aaa', '#dddbcb', '#f5f1e3', '#ffffff'],
    specificTransforms: {},
  },
  ffa69eff7e6b8c5e58a9f0d1fff7f8: {
    colors: ['#ffa69e', '#ff7e6b', '#8c5e58', '#a9f0d1', '#fff7f8'],
    specificTransforms: {},
  },
  ffe74cff5964ffffff38618c35a7ff: {
    colors: ['#ffe74c', '#ff5964', '#ffffff', '#38618c', '#35a7ff'],
    specificTransforms: {},
  },
  '272727fed766009fb7696773eff1f3': {
    colors: ['#272727', '#fed766', '#009fb7', '#696773', '#eff1f3'],
    specificTransforms: {},
  },
  '01161e124559598392aec3b0eff6e0': {
    colors: ['#01161e', '#124559', '#598392', '#aec3b0', '#eff6e0'],
    specificTransforms: {},
  },
  '2f485833658a86bbd8f6ae2df26419': {
    colors: ['#2f4858', '#33658a', '#86bbd8', '#f6ae2d', '#f26419'],
    specificTransforms: {},
  },
  '08070540434e702632912f40fffffa': {
    colors: ['#080705', '#40434e', '#702632', '#912f40', '#fffffa'],
    specificTransforms: {},
  },
  '08678807a0c3f0c808fff1d0dd1c1a': {
    colors: ['#086788', '#07a0c3', '#f0c808', '#fff1d0', '#dd1c1a'],
    specificTransforms: {},
  },
  edae49d1495b00798c30638e003d5b: {
    colors: ['#edae49', '#d1495b', '#00798c', '#30638e', '#003d5b'],
    specificTransforms: {},
  },
  efc7c2ffe5d4bfd3c168a691694f5d: {
    colors: ['#efc7c2', '#ffe5d4', '#bfd3c1', '#68a691', '#694f5d'],
    specificTransforms: {},
  },
  '1f20414b3f72ffc857119da419647e': {
    colors: ['#1f2041', '#4b3f72', '#ffc857', '#119da4', '#19647e'],
    specificTransforms: {},
  },
  ddfff793e1d8ffa69eaa4465462255: {
    colors: ['#ddfff7', '#93e1d8', '#ffa69e', '#aa4465', '#462255'],
    specificTransforms: {},
  },
  efd9cedec0f1b79ced957fef7161ef: {
    colors: ['#efd9ce', '#dec0f1', '#b79ced', '#957fef', '#7161ef'],
    specificTransforms: {},
  },
  e54b4bffa987f7ebe84441401e1e24: {
    colors: ['#e54b4b', '#ffa987', '#f7ebe8', '#444140', '#1e1e24'],
    specificTransforms: {},
  },
  '0d063018314f384e778bbeb2e6f9af': {
    colors: ['#0d0630', '#18314f', '#384e77', '#8bbeb2', '#e6f9af'],
    specificTransforms: {},
  },
  f7d1cde8c2cad1b3c4b392ac735d78: {
    colors: ['#f7d1cd', '#e8c2ca', '#d1b3c4', '#b392ac', '#735d78'],
    specificTransforms: {},
  },
  '97f9f9a4def9c1e0f7cfbae1c59fc9': {
    colors: ['#97f9f9', '#a4def9', '#c1e0f7', '#cfbae1', '#c59fc9'],
    specificTransforms: {},
  },
  '0a24633e92ccfffaffd8315b1e1b18': {
    colors: ['#0a2463', '#3e92cc', '#fffaff', '#d8315b', '#1e1b18'],
    specificTransforms: {},
  },
  '725752878e8896c0b7d4dfc7fef6c9': {
    colors: ['#725752', '#878e88', '#96c0b7', '#d4dfc7', '#fef6c9'],
    specificTransforms: {},
  },
  '8e9aafcbc0d3efd3d7feeafadee2ff': {
    colors: ['#8e9aaf', '#cbc0d3', '#efd3d7', '#feeafa', '#dee2ff'],
    specificTransforms: {},
  },
  '25090238040e640d14800e13ad2831': {
    colors: ['#250902', '#38040e', '#640d14', '#800e13', '#ad2831'],
    specificTransforms: {},
  },
  '042a2b5eb1bfcdedf6ef7b45d84727': {
    colors: ['#042a2b', '#5eb1bf', '#cdedf6', '#ef7b45', '#d84727'],
    specificTransforms: {},
  },
  '533a716184d850c5b79cec5bf0f465': {
    colors: ['#533a71', '#6184d8', '#50c5b7', '#9cec5b', '#f0f465'],
    specificTransforms: {},
  },
  ebd4cbda9f93b6465f8906202c0703: {
    colors: ['#ebd4cb', '#da9f93', '#b6465f', '#890620', '#2c0703'],
    specificTransforms: {},
  },
  ccdad19caea97885856f686638302e: {
    colors: ['#ccdad1', '#9caea9', '#788585', '#6f6866', '#38302e'],
    specificTransforms: {},
  },
  '666a86788aa392b6b1b2c9abe8ddb5': {
    colors: ['#666a86', '#788aa3', '#92b6b1', '#b2c9ab', '#e8ddb5'],
    specificTransforms: {},
  },
  cc444bda5552df7373e39695e4b1ab: {
    colors: ['#cc444b', '#da5552', '#df7373', '#e39695', '#e4b1ab'],
    specificTransforms: {},
  },
  '55dde033658a2f4858f6ae2df26419': {
    colors: ['#55dde0', '#33658a', '#2f4858', '#f6ae2d', '#f26419'],
    specificTransforms: {},
  },
  '424b54b38d97d5aca9ebcfb2c5baaf': {
    colors: ['#424b54', '#b38d97', '#d5aca9', '#ebcfb2', '#c5baaf'],
    specificTransforms: {},
  },
  f7f4eaded9e2c0b9dd80a1d475c9c8: {
    colors: ['#f7f4ea', '#ded9e2', '#c0b9dd', '#80a1d4', '#75c9c8'],
    specificTransforms: {},
  },
  ee605560d394aaf683ffd97dff9b85: {
    colors: ['#ee6055', '#60d394', '#aaf683', '#ffd97d', '#ff9b85'],
    specificTransforms: {},
  },
  '586ba4324376f5dd90f68e5ff76c5e': {
    colors: ['#586ba4', '#324376', '#f5dd90', '#f68e5f', '#f76c5e'],
    specificTransforms: {},
  },
  faa275ff8c61ce6a859852775c374c: {
    colors: ['#faa275', '#ff8c61', '#ce6a85', '#985277', '#5c374c'],
    specificTransforms: {},
  },
  '4612208c2f39b23a48fcb9b2fed0bb': {
    colors: ['#461220', '#8c2f39', '#b23a48', '#fcb9b2', '#fed0bb'],
    specificTransforms: {},
  },
  c33c54254e7037718e8ee3efaef3e7: {
    colors: ['#c33c54', '#254e70', '#37718e', '#8ee3ef', '#aef3e7'],
    specificTransforms: {},
  },
  '0b3954087e8bbfd7eaff5a5fc81d25': {
    colors: ['#0b3954', '#087e8b', '#bfd7ea', '#ff5a5f', '#c81d25'],
    specificTransforms: {},
  },
  '4d9de0e15554e1bc293bb2737768ae': {
    colors: ['#4d9de0', '#e15554', '#e1bc29', '#3bb273', '#7768ae'],
    specificTransforms: {},
  },
  '6b717eefaac4ffc4d1ffe8e1d4dccd': {
    colors: ['#6b717e', '#efaac4', '#ffc4d1', '#ffe8e1', '#d4dccd'],
    specificTransforms: {},
  },
  ffaf87ff8e72ed6a5e4ce0b3377771: {
    colors: ['#ffaf87', '#ff8e72', '#ed6a5e', '#4ce0b3', '#377771'],
    specificTransforms: {},
  },
  dbc2cf9fa2b23c7a892e475616262e: {
    colors: ['#dbc2cf', '#9fa2b2', '#3c7a89', '#2e4756', '#16262e'],
    specificTransforms: {},
  },
  '9f7e69d2bba0f2efc7f7ffe0ffeee2': {
    colors: ['#9f7e69', '#d2bba0', '#f2efc7', '#f7ffe0', '#ffeee2'],
    specificTransforms: {},
  },
  '1f242121686949a0789cc5a1dce1de': {
    colors: ['#1f2421', '#216869', '#49a078', '#9cc5a1', '#dce1de'],
    specificTransforms: {},
  },
  '5337475f506b6a6b8376949f86bbbd': {
    colors: ['#533747', '#5f506b', '#6a6b83', '#76949f', '#86bbbd'],
    specificTransforms: {},
  },
  '70d6ffff70a6ff9770ffd670e9ff70': {
    colors: ['#70d6ff', '#ff70a6', '#ff9770', '#ffd670', '#e9ff70'],
    specificTransforms: {},
  },
  baf2bbbaf2d8bad7f2f2bac9f2e2ba: {
    colors: ['#baf2bb', '#baf2d8', '#bad7f2', '#f2bac9', '#f2e2ba'],
    specificTransforms: {},
  },
  e5f9e0a3f7b540c9a22f9c95664147: {
    colors: ['#e5f9e0', '#a3f7b5', '#40c9a2', '#2f9c95', '#664147'],
    specificTransforms: {},
  },
  '01295f437f97849324ffb30ffd151b': {
    colors: ['#01295f', '#437f97', '#849324', '#ffb30f', '#fd151b'],
    specificTransforms: {},
  },
  '5c9eadffffff326273eeeeeee39774': {
    colors: ['#5c9ead', '#ffffff', '#326273', '#eeeeee', '#e39774'],
    specificTransforms: {},
  },
  '00a6fb0582ca006494003554051923': {
    colors: ['#00a6fb', '#0582ca', '#006494', '#003554', '#051923'],
    specificTransforms: {},
  },
  '3f7cac95afbabdc4a7d5e1a3e2f89c': {
    colors: ['#3f7cac', '#95afba', '#bdc4a7', '#d5e1a3', '#e2f89c'],
    specificTransforms: {},
  },
  '0000000c18211b2a41324a5fccc9dc': {
    colors: ['#000000', '#0c1821', '#1b2a41', '#324a5f', '#ccc9dc'],
    specificTransforms: {},
  },
  f79f79f7d08ae3f09b87b6a75b5941: {
    colors: ['#f79f79', '#f7d08a', '#e3f09b', '#87b6a7', '#5b5941'],
    specificTransforms: {},
  },
  ff6978fffcf9b1ede86d435a352d39: {
    colors: ['#ff6978', '#fffcf9', '#b1ede8', '#6d435a', '#352d39'],
    specificTransforms: {},
  },
  '4281a448a9a6e4dfdad4b483c1666b': {
    colors: ['#4281a4', '#48a9a6', '#e4dfda', '#d4b483', '#c1666b'],
    specificTransforms: {},
  },
  '0005053b33555d5d81bfcde0fefcfd': {
    colors: ['#000505', '#3b3355', '#5d5d81', '#bfcde0', '#fefcfd'],
    specificTransforms: {},
  },
  bee9e862b6cb1b4965cae9ff5fa8d3: {
    colors: ['#bee9e8', '#62b6cb', '#1b4965', '#cae9ff', '#5fa8d3'],
    specificTransforms: {},
  },
  '93162128464b3267712c8c9942d9c8': {
    colors: ['#931621', '#28464b', '#326771', '#2c8c99', '#42d9c8'],
    specificTransforms: {},
  },
  a4243bd8c99bd8973cbd632f273e47: {
    colors: ['#a4243b', '#d8c99b', '#d8973c', '#bd632f', '#273e47'],
    specificTransforms: {},
  },
  ee635259cd903fa7d6fac05ef79d84: {
    colors: ['#ee6352', '#59cd90', '#3fa7d6', '#fac05e', '#f79d84'],
    specificTransforms: {},
  },
  '0a090822333beae0d5c6ac8f5e503f': {
    colors: ['#0a0908', '#22333b', '#eae0d5', '#c6ac8f', '#5e503f'],
    specificTransforms: {},
  },
  f6f7ebe94f37393e413f88c544bba4: {
    colors: ['#f6f7eb', '#e94f37', '#393e41', '#3f88c5', '#44bba4'],
    specificTransforms: {},
  },
  dad2d81436420f8b8dec9a29a8201a: {
    colors: ['#dad2d8', '#143642', '#0f8b8d', '#ec9a29', '#a8201a'],
    specificTransforms: {},
  },
  '0fa3b1b5e2faf9f7f3eddea4f7a072': {
    colors: ['#0fa3b1', '#b5e2fa', '#f9f7f3', '#eddea4', '#f7a072'],
    specificTransforms: {},
  },
  ffb8d1e4b4c2e7cee3e0e1e9ddfdfe: {
    colors: ['#ffb8d1', '#e4b4c2', '#e7cee3', '#e0e1e9', '#ddfdfe'],
    specificTransforms: {},
  },
  '231f20bb44307ebdc2f3dfa2efe6dd': {
    colors: ['#231f20', '#bb4430', '#7ebdc2', '#f3dfa2', '#efe6dd'],
    specificTransforms: {},
  },
  '13407413315c0b25458da9c4eef4ed': {
    colors: ['#134074', '#13315c', '#0b2545', '#8da9c4', '#eef4ed'],
    specificTransforms: {},
  },
  '424b5493a8acffffffe2b4bd9b6a6c': {
    colors: ['#424b54', '#93a8ac', '#ffffff', '#e2b4bd', '#9b6a6c'],
    specificTransforms: {},
  },
  '3772fff038ffef709de2ef7070e4ef': {
    colors: ['#3772ff', '#f038ff', '#ef709d', '#e2ef70', '#70e4ef'],
    specificTransforms: {},
  },
  '071e221d7874679289f4c095ee2e31': {
    colors: ['#071e22', '#1d7874', '#679289', '#f4c095', '#ee2e31'],
    specificTransforms: {},
  },
  cebebeece2d0d5b9b2a267696d2e46: {
    colors: ['#cebebe', '#ece2d0', '#d5b9b2', '#a26769', '#6d2e46'],
    specificTransforms: {},
  },
  '0c120cc201146d7275c7d6d5ecebf3': {
    colors: ['#0c120c', '#c20114', '#6d7275', '#c7d6d5', '#ecebf3'],
    specificTransforms: {},
  },
  e9d758297373ff8552e6e6e639393a: {
    colors: ['#e9d758', '#297373', '#ff8552', '#e6e6e6', '#39393a'],
    specificTransforms: {},
  },
  '3d348b7678edf7b801f18701f35b04': {
    colors: ['#3d348b', '#7678ed', '#f7b801', '#f18701', '#f35b04'],
    specificTransforms: {},
  },
  ed254ef9dc5cf4fffd011936465362: {
    colors: ['#ed254e', '#f9dc5c', '#f4fffd', '#011936', '#465362'],
    specificTransforms: {},
  },
  '6b9080a4c3b2cce3deeaf4f4f6fff8': {
    colors: ['#6b9080', '#a4c3b2', '#cce3de', '#eaf4f4', '#f6fff8'],
    specificTransforms: {},
  },
  '223843eff1f3dbd3d8d8b4a0d77a61': {
    colors: ['#223843', '#eff1f3', '#dbd3d8', '#d8b4a0', '#d77a61'],
    specificTransforms: {},
  },
  '03192646818977aca29dbebbf4e9cd': {
    colors: ['#031926', '#468189', '#77aca2', '#9dbebb', '#f4e9cd'],
    specificTransforms: {},
  },
  ffbc42d811598f2d5621838073d2de: {
    colors: ['#ffbc42', '#d81159', '#8f2d56', '#218380', '#73d2de'],
    specificTransforms: {},
  },
  f1dac4a69cac474973161b330d0c1d: {
    colors: ['#f1dac4', '#a69cac', '#474973', '#161b33', '#0d0c1d'],
    specificTransforms: {},
  },
  '63474daa767cd6a184ffa686fec196': {
    colors: ['#63474d', '#aa767c', '#d6a184', '#ffa686', '#fec196'],
    specificTransforms: {},
  },
  '000000839788eee0cbbaa898bfd7ea': {
    colors: ['#000000', '#839788', '#eee0cb', '#baa898', '#bfd7ea'],
    specificTransforms: {},
  },
  '000000cf5c36eee5e97c7c7cefc88b': {
    colors: ['#000000', '#cf5c36', '#eee5e9', '#7c7c7c', '#efc88b'],
    specificTransforms: {},
  },
  '031d4404395e70a288dab785d5896f': {
    colors: ['#031d44', '#04395e', '#70a288', '#dab785', '#d5896f'],
    specificTransforms: {},
  },
  F04358FFD26205D5AA107FB107364C: {
    colors: ['#F04358', '#FFD262', '#05D5AA', '#107FB1', '#07364C'],
    specificTransforms: {},
  },
  '5D0D6EED4353FED4403CCDB40FAC71': {
    colors: ['#5D0D6E', '#ED4353', '#FED440', '#3CCDB4', '#0FAC71'],
    specificTransforms: {},
  },
}
