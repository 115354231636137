import { MaposaicColors } from 'Colors/types'
import { Size } from 'Canvas/types'

export type PaintWorkerData = {
  sourcePixelArray: Uint8Array
  targetPixelArray: Uint8ClampedArray
  sourceSize: Size
  canvassRatio: number
  maposaicColors: MaposaicColors
  roadColorThreshold: number
  similarColorTolerance: number
}

export type SpecificColorTransform = { color: string | null; name: string; isEditable: boolean }
export type SpecificColorTransforms = Record<string, SpecificColorTransform>

export enum MapboxStyle {
  Details = 'details',
  Road = 'road',
  Water = 'water',
  Administrative = 'administrative',
}

export const MAPOSAIC_STYLE_URL_PARAM_KEY = 'style'
export const MAPOSAIC_SCREENSAVER_PARAM_KEY = 'screensaver'
export const MAP_TITLE_PARAM_KEY = 'title'

export enum MaposaicGeoURLParamKey {
  Lat = 'lat',
  Lng = 'lng',
  Zoom = 'zoom',
  Bearing = 'bearing',
}

export enum MaposaicColorURLParamKey {
  Color = 'color',
  Seed = 'seed',
  Origin = 'origin',
  Key = 'key',
  Index = 'index',
  Colors = 'colors',
  Specific = 'specific',
}

export enum MosaicMode {
  'Map',
  'Image',
}
